const colors = [
  '#0179A8',
  '#346CB0',
  '#5F4B8B',
  '#B76BA3',
  '#EA6759',
  '#EC935E',
  '#F7C46C',
  '#A7C796',
  '#00A28A',
  '#3686A0',
  '#0179A8',
  '#346CB0'
]

export default colors
